import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { getImageUrl, initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";

import Section from "../Section";
import { Reveal } from "react-reveal";

const identifier = "lpEmailserie";

function EmailSerieLandingPage({ locale }) {
  const [page, setPage] = useState({
    identifier: identifier,
    nav: "emailserie",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: {},
  });

  const init = () => {
    initPage(page, (page) => setPage({ ...page }));
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  const { data } = page;

  return (
    <div className={`page main-page landingpage page-${page.identifier}`}>
      <MainLayout page={page}>
        <Section>
          {page.locale !== "de" && (
            <div className="small warning callout">
              <strong>Note:</strong> This email series is only available in 🇩🇪
              German right now.
            </div>
          )}
          <Reveal>
            <Grid>
              <Row margin="xy">
                <Cell sm={24} md={14}>
                  {data.PreTitle && (
                    <div className="page-pretitle">{data.PreTitle}</div>
                  )}
                  <h1>{data.Title}</h1>
                  <div
                    className="introtext"
                    dangerouslySetInnerHTML={{ __html: data.IntroText }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: data.Content }} />
                </Cell>
                <Cell sm={24} md={10}>
                  {data.TitleImage && (
                    <img src={getImageUrl(data.TitleImage)} alt="" />
                  )}
                  <iframe
                    title="E-Mail-Serie"
                    src="https://static.patricktschirpke.com/emailserie.html"
                    frameBorder={0}
                    style={{ width: "100%", minHeight: "440px" }}
                  />
                </Cell>
              </Row>
            </Grid>
          </Reveal>
        </Section>
      </MainLayout>
    </div>
  );
}

export default EmailSerieLandingPage;
